import $ from "jquery";


  function getParams(attr) {
      const name = attr.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

      const regex = new RegExp(`[\\?&]${  name  }=([^&#]*)`);
      const results = regex.exec(location.search);

      return results === null
          ? ''
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  function heroFormSubmit() {
      const $elem = $(".hero .wpcf7-form");
      console.log('Integration started');

      $elem.submit(function (e) {
          e.preventDefault();
          const url = "https://golani.hypnobox.com.br/email.receber.php";
          const self = $(this);
          const utmSource = getParams('utm_source');
          const message = self.find("textarea[name=your-textarea]").val();
          const formData = {
              nome: self.find("input[name=nome]").val(),
              email: self.find("input[name=email]").val(),
              mensagem: "Quero mais informações.",
              id_produto: '1',
              tel_celular: self.find("input[name=tel_celular]").val(),
              midia: 'direto'
          };

          if (!formData.nome || !formData.email)  {
              swal('Preencha todos os campos corretamente');
              return false
          }

          if (message) {
              formData.mensagem = message;
          }

          if (utmSource) {
              formData.midia = utmSource;
          }

          console.log('Form: ', formData);

          $.ajax({
              "async": true,
              "crossDomain": true,
              "url": url,
              "method": "POST",
              "headers": {
                  "content-type": "application/x-www-form-urlencoded"
              },
              "data": formData
          }).done(function (response) {
              // window.location.href = "";
              console.log(response);
          });

          return false;
      });
  }

  function footerFormSubmit() {
    const $elem = $("section.contact .wpcf7-form");
    console.log('Integration started 2');

    $elem.submit(function (e) {
        e.preventDefault();
        const url = "https://golani.hypnobox.com.br/email.receber.php";
        const self = $(this);
        const utmSource = getParams('utm_source');
        const message = self.find("textarea[name=your-textarea]").val();
        const formData = {
            nome: self.find("input[name=nome]").val(),
            email: self.find("input[name=email]").val(),
            mensagem: self.find("textarea[name=message]").val(),
            id_produto: '1',
            tel_celular: self.find("input[name=tel_celular]").val(),
            midia: 'direto'
        };

        if (!formData.nome || !formData.email)  {
            swal('Preencha todos os campos corretamente');
            return false
        }

        if (message) {
            formData.mensagem = message;
        }

        if (utmSource) {
            formData.midia = utmSource;
        }

        console.log('Form: ', formData);

        $.ajax({
            "async": true,
            "crossDomain": true,
            "url": url,
            "method": "POST",
            "headers": {
                "content-type": "application/x-www-form-urlencoded"
            },
            "data": formData
        }).done(function (response) {
            // window.location.href = "";
            console.log(response);
        });

        return false;
    });
}

export { heroFormSubmit, footerFormSubmit };
