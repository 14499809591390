import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "./LocomotiveScroll";
import { $ } from "@utils/dom";

export const SELECTOR = ".scroll-container";

export default class NewSiteScroll {
  constructor() {
    this.container = null;
    this.locoScroll = null;
    this.resizeObserver = null;

    this.init = this.init.bind(this);
    this.start = this.start.bind(this);
    this.update = this.update.bind(this);
    this.destroy = this.destroy.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  init() {
    this.container = $(SELECTOR);
    this.locoScroll = LocomotiveScroll(this.container, {
      smooth: true,
      smoothMobile: true,
      lerp: 0.07,
    });

    this.locoScroll.on("scroll", this.onScroll);

    this.resizeObserver = new ResizeObserver(() => {
      this.update();
    });

    this.resizeObserver.observe(this.container);
  }

  start() {
    if (this.locoScroll) {
      this.locoScroll.start();
    }
  }

  update() {
    if (this.locoScroll) {
      this.locoScroll.update();
    }

    try {
      ScrollTrigger.refresh();
    } catch (error) {}
  }

  destroy() {
    if (this.locoScroll) {
      this.locoScroll.destroy();
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.container = null;
    this.locoScroll = null;
    this.resizeObserver = null;
  }

  onScroll(instance) {}
}
