import $ from "jquery";
import Inputmask from "inputmask";

function toggleFaq() {
  $(".question").on("click", function() {
    $(this).toggleClass("open");
    if ($(this).hasClass("open")) {
      $(".answer", this).css({"maxHeight": `${$(".answer", this)[0].scrollHeight}px`})
    }else {
      $(".answer", this).css({"maxHeight": "0px"})
    };
  })
}

function toggleNav() {
	$(".navbar .toggle").on("click", function () {
		$("body").toggleClass("is-menu-open");
	});

	$(".menu-mobile a").on("click", function () {
		$("body").removeClass("is-menu-open");
	});
}

function toggleSlider() {
  const tabs = document.querySelectorAll('.tab');
  const wrapper = document.querySelectorAll('.wrapper')

  tabs.forEach(tab => {
    const index = Array.from(tab.parentElement.children).indexOf(
      tab,
    );
    tab.addEventListener('click', () => {
      tabs[0].classList.remove('active');
      wrapper[0].classList.remove('active');
      tabs[1].classList.remove('active');
      wrapper[1].classList.remove('active');

      tabs[index].classList.add('active');
      wrapper[index].classList.add('active');
      console.log(wrapper[index])
    })
  })
}

function clickChat() {
  $("#chat").on("click", () => {
    document.getElementById("chatbox").click();
  })
}

function phoneMask() {
  Inputmask('(99) 99999 9999').mask($('input[type=tel]'))
}

export { toggleFaq, toggleNav, toggleSlider, clickChat, phoneMask };
