import Locomotive from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import { gsap } from "gsap";

export default function LocomotiveScroll(element, options) {
  gsap.registerPlugin(ScrollTrigger);

  const locoScroll = new Locomotive({
    el: element,
    ...options,
  });

  locoScroll.on("scroll", ScrollTrigger.update);

  ScrollTrigger.scrollerProxy(element, {
    scrollTop(value) {
      return arguments.length
        ? locoScroll.scrollTo(value, 0, 0)
        : locoScroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    pinType: element.style.transform ? "transform" : "fixed",
  });

  ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

  return locoScroll;
}
