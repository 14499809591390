import { toggleFaq, toggleNav, toggleSlider, clickChat, phoneMask } from "../modules/tricks";
import { perspectivesLightbox } from "../modules/lightbox";
import { perspectives, perspectivesFull, plant1, plant2 } from "../modules/slide";
import { heroFormSubmit, footerFormSubmit } from "../modules/form";

export default [
  {
    namespace: "home",
    beforeEnter() {
      toggleFaq();
      toggleNav();
      toggleSlider();
      perspectives();
      perspectivesFull();
      plant1();
      plant2();
      heroFormSubmit();
      footerFormSubmit();
      clickChat();
      phoneMask();
    },
  },
  {
    namespace: "blog",
    beforeEnter() {
    },
  },
];
