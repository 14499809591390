import Swiper, { Navigation } from "swiper";

function perspectives() {
  new Swiper("section.perspectives .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: "section.perspectives .swiper-button-next",
      prevEl: "section.perspectives .swiper-button-prev",
    },
  });
}

function perspectivesFull() {
  new Swiper("section.perspectives-full .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: "section.perspectives-full .swiper-button-next",
      prevEl: "section.perspectives-full .swiper-button-prev",
    },
  });
}

function plant1() {
  new Swiper("section.plants .wrapper.first .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: ".wrapper.first .swiper-button-next",
      prevEl: ".wrapper.first .swiper-button-prev",
    },
  });
}

function plant2() {
  new Swiper("section.plants .wrapper.second .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: ".wrapper.second .swiper-button-next",
      prevEl: ".wrapper.second .swiper-button-prev",
    },
  });
}

export { perspectives, perspectivesFull, plant1, plant2 };
